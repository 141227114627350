import aboutUs from "./AboutusShow";
import NavBar from "./NavBarShow";
import Footer from "./FooterShow";
import heroSection from "./HeroSectionShow";
import story from "./StoryShow";
import ServicesSection from "./ServicesSection";
import breadcrumb from "./breadcrumb";

export default {
	aboutUs,
	ServicesSection,
	heroSection,
	story,
	Footer,
	NavBar,
	breadcrumb,
};
