import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
} from "reactstrap";
import { v4 } from "uuid";
// needed assets
import userPlaceholder from "../../../../assets/images/placeholder.jpg";
import { useDispatch } from "react-redux";
import { updateMeta } from "store/actions/metaAction";

const StoryShow = ({ ID, loadersetter }) => {
	const dispatch = useDispatch();
	const [formData, setFormData] = useState();
	const [currentFeature, setCurrentFeature] = useState({});
	const [dataLoaded, setDataLoaded] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const toggleModal = () => setModalOpen(!modalOpen);
	const storyForm = useRef(null);

	const history = useHistory();
	useEffect(() => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل قيمنا",
				breadcrumb: "قيم الموقع",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/site-infos/sections/${ID}`, {
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				// toast.info("جارء ادخال البيانات");
				setTimeout(() => {
					setFormData({ ...response.data.data.section });
					setDataLoaded(true);
					// toast.success("يمكنك التعديل الان 😊") &&
					loadersetter(false);
				}, 1000);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	}, []);

	const uploadImage = e => {
		try {
			loadersetter(true);
			const data = new FormData();
			data.append("file", e.target.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}upload`, data, {
					headers: {
						Authorization: `Bearer ${Cookies.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					setFormData({ ...formData, image: response.data.data.file.path });
					loadersetter(false);
				});
		} catch (error) {
			console.log("test");
			loadersetter(false);
		}
	};

	const addNewFeature = e => {
		e.preventDefault();
		if (
			// !currentFeature.storyYear ||
			// currentFeature.storyYear === 0 ||
			!currentFeature.description ||
			!currentFeature.title
		) {
			toast.warn("الرجاء اذخل القصة بشكل صحيح");
			return;
		}
		dataLoaded &&
			setFormData({
				...formData,
				storys: [...(formData?.storys ?? []), { ...currentFeature, id: v4() }],
			});
		setCurrentFeature({});
		storyForm.current.reset();
	};

	const submite = () => {
		loadersetter(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}pages/site-infos/sections/${ID}`,
				{
					section: { ...formData, slug: ID },
				},
				{
					headers: {
						Authorization: `Bearer ${Cookies.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(res => {
				history.push("/siteinfo/index");
				toast.success("تم التحديث بنجاح!");
			});
	};
	return (
		<div>
			<Container>
				<Row>
					<div className="col-md-8">
						<div className="card mb-2">
							<div className="card-body">
								<Container>
									<Row>
										<Col>
											<div className="form-item">
												<label htmlFor="description">وصف السكشن بالعربية</label>
												<textarea
													id="descriptionEn"
													value={formData?.description?.ar}
													onChange={e =>
														dataLoaded &&
														setFormData({
															...formData,
															description: {
																...formData?.description,
																ar: e.target.value,
															},
														})
													}
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<Col>
											<div className="form-item">
												<label htmlFor="description">
													وصف السكشن بالانجليزية
												</label>
												<textarea
													id="descriptionEn"
													value={formData?.description?.en}
													onChange={e =>
														dataLoaded &&
														setFormData({
															...formData,
															description: {
																...formData?.description,
																en: e.target.value,
															},
														})
													}
												/>
											</div>
										</Col>
									</Row>
								</Container>
							</div>
						</div>
						<div className="card">
							<div className="card-body">
								<form ref={storyForm}>
									<Container>
										{/* <Row>
											<Col>
												<div className="form-item">
													<label htmlFor="description">سنة القصة</label>
													<input
														type="text"
														id="descriptionEn"
														onChange={e =>
															dataLoaded &&
															setCurrentFeature({
																...currentFeature,
																storyYear: e.target.value,
															})
														}
													/>
												</div>
											</Col>
										</Row> */}
										<Row>
											<Col xs={12}>
												<div className="form-item">
													<label htmlFor="title">عنوان القصة</label>
													<input
														type="text"
														id="title"
														onChange={e =>
															dataLoaded &&
															setCurrentFeature({
																...currentFeature,
																title: {
																	...currentFeature?.title,
																	ar: e.target.value,
																},
															})
														}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<div className="form-item">
													<label htmlFor="title">عنوان القصة بالانجليزية</label>
													<input
														type="text"
														id="titleEn"
														onChange={e =>
															dataLoaded &&
															setCurrentFeature({
																...currentFeature,
																title: {
																	...currentFeature?.title,
																	en: e.target.value,
																},
															})
														}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="form-item">
													<label htmlFor="description">وصف القصة</label>
													<input
														type="text"
														id="descriptionAr"
														onChange={e =>
															dataLoaded &&
															setCurrentFeature({
																...currentFeature,
																description: {
																	...currentFeature?.description,
																	ar: e.target.value,
																},
															})
														}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="form-item">
													<label htmlFor="description">
														وصف القصة بالانجليزية
													</label>
													<input
														type="text"
														id="descriptionEn"
														onChange={e =>
															dataLoaded &&
															setCurrentFeature({
																...currentFeature,
																description: {
																	...currentFeature?.description,
																	en: e.target.value,
																},
															})
														}
													/>
												</div>
											</Col>
										</Row>

										<Row>
											<Col>
												<div className="form-item mt-3">
													<button
														className="bt bt-success btn-block"
														onClick={addNewFeature}
													>
														اضافة قصة جديده
													</button>
												</div>
											</Col>
										</Row>
										<Row>
											{formData?.storys?.map((story, index) => (
												<Col key={story.id + `feature`}>
													<div
														style={{
															display: "flex",
															justifyContent: "flex-start",
															alignItems: "center",
															maxWidth: "99%",
														}}
													>
														<span
															style={{
																width: "69px",
																height: "64px",
																minWidth: "69px",
																background: "#00c6bb",
																border: "2px solid #ffffff",
																boxSizing: "border-box",
																boxShadow: "0px 4px 4px rgb(216 216 216 / 25%)",
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																marginLeft: "14px",
																color: "#fff",
															}}
														>
															{story?.storyYear ?? 0}
														</span>
														<div
															className=""
															style={{ padding: "1rem", maxWidth: "80%" }}
														>
															<h1
																style={{
																	fontSize: "18px",
																	marginBottom: "9px",
																	fontWeight: "bold",
																}}
															>
																{story?.title?.ar ?? ""}
															</h1>
															<p
																style={{
																	fontSize: "14px",
																	color: "#303c45",
																	margin: "0",
																	overflow: "hidden",
																	whiteSpace: "nowrap",
																	maxWidth: "99%",
																	textOverflow: "ellipsis",
																}}
															>
																{story?.description?.ar ?? ""}
															</p>
														</div>
														<i
															onClick={e => {
																setCurrentFeature({ ...story, index });
																toggleModal();
															}}
															style={{
																transition: "all ease 300ms",
																color: "rgb(53 127 240)",
																cursor: "pointer",
																marginInline: "0.2rem",
															}}
															class="fas fa-pencil-alt"
														></i>
														<i
															onClick={e => {
																e.target.style.transform = "scale(1.3)";
																e.target.style.color = "red";
																setTimeout(
																	() =>
																		// toast.info("تم مسح القصة ") &&
																		setFormData({
																			...formData,
																			storys: [
																				...formData.storys.filter(
																					fstory => fstory.id !== story.id
																				),
																			],
																		}),
																	350
																);
															}}
															style={{
																transition: "all ease 300ms",
																color: "rgb(240 53 53)",
																cursor: "pointer",
																marginInline: "0.2rem",
															}}
															class="fas fa-trash-alt"
														></i>
													</div>
												</Col>
											))}
										</Row>
									</Container>
								</form>
							</div>
						</div>
					</div>
					{modalOpen && (
						<EditModal
							{...{
								currentFeature,
								setCurrentFeature,
								formData,
								setFormData,
								toggleModal,
							}}
						/>
					)}
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={uploadImage} id="picture" />
									<img
										src={
											formData?.image
												? `${process.env.REACT_APP_MAIN_URL}${formData.image}`
												: userPlaceholder
										}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block" onClick={submite}>
								ارسال
							</button>
						</div>
					</div>
				</Row>
			</Container>
		</div>
	);
};

const EditModal = ({
	currentFeature,
	setCurrentFeature,
	formData,
	setFormData,
	toggleModal,
}) => {
	return (
		<Modal isOpen={true} toggle={toggleModal}>
			<ModalBody>
				<Row>
					<Col xs={12}>
						<div className="form-item">
							<label htmlFor="title">عنوان القصة</label>
							<input
								type="text"
								id="title"
								onChange={e => {
									setCurrentFeature({
										...currentFeature,
										title: {
											...currentFeature?.title,
											ar: e.target.value,
										},
									});
								}}
								defaultValue={currentFeature?.title?.ar}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className="form-item">
							<label htmlFor="title">عنوان القصة بالانجليزية</label>
							<input
								type="text"
								id="titleEn"
								onChange={e =>
									setCurrentFeature({
										...currentFeature,
										title: {
											...currentFeature?.title,
											en: e.target.value,
										},
									})
								}
								defaultValue={currentFeature?.title?.en}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="form-item">
							<label htmlFor="description">وصف القصة</label>
							<input
								type="text"
								id="descriptionAr"
								onChange={e =>
									setCurrentFeature({
										...currentFeature,
										description: {
											...currentFeature?.description,
											ar: e.target.value,
										},
									})
								}
								defaultValue={currentFeature?.description?.ar}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="form-item">
							<label htmlFor="description">وصف القصة بالانجليزية</label>
							<input
								type="text"
								id="descriptionEn"
								onChange={e =>
									setCurrentFeature({
										...currentFeature,
										description: {
											...currentFeature?.description,
											en: e.target.value,
										},
									})
								}
								defaultValue={currentFeature?.description?.en}
							/>
						</div>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					color="success"
					onClick={() => {
						console.log("currentFeature?.index", currentFeature?.index);
						if (currentFeature?.index >= 0) {
							let formTmp = formData;
							formTmp.storys[currentFeature.index] = currentFeature;
							setFormData(formTmp);
						}
						toggleModal();
					}}
				>
					حفظ
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default StoryShow;
