import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const AutomotiveSplitersIndex = () => {
	// const [images, setImages] = useState([]);
	const [titles, setTitles] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الفواصل",
				breadcrumb: "الفواصل",
				button: {
					show: true,
					text: "اضافة فاصل جديد",
					link: "/automotivePage/automotiveSpliters/create",
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/outomotive-spliter/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				console.log(response, "this is the response");
				setTitles(response.data.data[0].categories[0].posts);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const columns = [
		{
			name: "العنوان",
			sortable: true,
			cell: row => {
				console.log(row, "this is row");
				return row.data.title.ar;
			},
		},
		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link
							className="bt bt-sm bt-success"
							to={`/automotivePage/automotiveSpliters/show/${row.id}`}
						>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfirmDelete(row.id)}
						>
							<i className="fas fa-trash" />
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfirmDelete = automotiveSplitersId => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteSlide(automotiveSplitersId),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteSlide = automotiveSplitersId => {
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/posts/${automotiveSplitersId}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="datatable">
				<DataTableExtensions columns={columns} data={titles}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default AutomotiveSplitersIndex;
